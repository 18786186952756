import React from "react";
import Intro from "../partials/intro";
import How from "../partials/how";
import Edafacecomponents from "../partials/edafacecomponents";
import Footer2 from "../partials/footer2";
import About from "../partials/about";
import Nav from "../partials/nav";
import EdafaceVideo from "../partials/edavideo";

const Landing = () => {
  return (
    <div>
      <Nav></Nav>
      <Intro></Intro>
      <EdafaceVideo></EdafaceVideo>
      <How></How>
      <About></About>
      <Edafacecomponents></Edafacecomponents>

      <Footer2></Footer2>
    </div>
  );
};

export default Landing;
