import React from "react";
import Nav from "../partials/nav";
import { useEffect } from "react";

import { Chrono } from "react-chrono";
import Footer2 from "../partials/footer2";
import checkOutline from "../../images/checkOutline.svg";
import check from "../../images/check.svg";

const Roadmap = () => {
  const data = [
    {
      title: "May to August 2021",
      cardTitle: " Creation of EdaFace",
      url: "http://google.com",
      cardSubtitle: "",

      cardDetailedText: (
        <ul className="time-line">
          <li>
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Vision Conceptualization
          </li>
          <li>
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Branding{" "}
          </li>
          <li>
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Company Registration
          </li>
        </ul>
      ),
    },
    {
      title: "September to December 2021",
      cardTitle: "Project Team",
      url: "http://google.com",
      cardSubtitle: ``,
      cardDetailedText: (
        <ul className="time-line">
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Formation of Project Team
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Documentation and Outline of Project Structure
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Initial training of Project Team
          </li>
        </ul>
      ),
    },
    {
      title: "January to March 2022",
      cardTitle: "Litepaper & Social Media Outreach",
      url: "",
      cardSubtitle: ``,
      cardDetailedText: (
        <ul className="time-line">
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Seed Phase (venture capital)
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Creating social media platforms
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Release of EdaFace Litepaper
          </li>
        </ul>
      ),
    },
    {
      title: "April to June 2022",
      cardTitle: "Eda Token Formation",
      url: "",
      cardSubtitle: ``,
      cardDetailedText: (
        <ul className="time-line">
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Unveiling of Eda Token White Paper
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Building of Eda Token smart contracts
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Launching of Eda Token
          </li>
        </ul>
      ),
    },
    {
      title: "July to September 2022",
      cardTitle: "Initial Market Awareness & Launchpad",
      url: "",
      cardSubtitle: ``,
      cardDetailedText: (
        <ul className="time-line">
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Creating initial market awareness on social media
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Documentation and Designing of the Launchpad
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Recruitment of new staff
          </li>
        </ul>
      ),
    },
    {
      title: "October to December 2022",
      cardTitle: "Newsfeed Formation",
      url: "",
      cardSubtitle: ``,
      cardDetailedText: (
        <ul className="time-line">
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Documentation and Designing of Newsfeed Platform
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Preliminary audit of Eda Token
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Further marketing awareness
          </li>
        </ul>
      ),
    },
    {
      title: "January to March 2023",
      cardTitle: "Listing Platforms & Digital Clinic",
      url: "",
      cardSubtitle: ``,
      cardDetailedText: (
        <ul className="time-line">
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Documentation and Designing of the Listing Platforms
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Architectural layout of Digital Clinic
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Training and retraining of staff
          </li>
        </ul>
      ),
    },
    {
      title: "April to June 2023",
      cardTitle: "NFT Marketplace & Blockchain Literature",
      url: "",
      cardSubtitle: ``,
      cardDetailedText: (
        <ul className="time-line">
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Documentation and Designing of the NFT Marketplace
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Documentation and Designing of Blockchain Literature
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Creating market awareness
          </li>
        </ul>
      ),
    },
    {
      title: "July to September 2023",
      cardTitle: "EdaFace Mall & P2P Market",
      url: "",
      cardSubtitle: ``,
      cardDetailedText: (
        <ul className="time-line">
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Documentation and Designing of EdaFace Mall
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Documentation and Designing of P2P Market
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Creating market awareness
          </li>
        </ul>
      ),
    },
    {
      title: "October to December 2023",
      cardTitle: "School of Crypto & Chat Forum",
      url: "",
      cardSubtitle: ``,
      cardDetailedText: (
        <ul className="time-line">
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Documentation and Designing of School of Cryptos
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Launching of EdaFace Chat Forum
          </li>
          <li>
            {" "}
            <img
              src={checkOutline}
              className="img-fluid check-push"
              alt="check"
            />
            Preparation for Intensive Marketing
          </li>
        </ul>
      ),
    },
    {
      title: "January to March 2024",
      cardTitle: "EdaFace Dex",
      url: "",
      cardSubtitle: ``,
      cardDetailedText: (
        <ul className="time-line">
          <li>
            {" "}
            <img src={check} className="img-fluid check-push" alt="check" />
            Documentation and Designing of EdaFace Dex
          </li>
          <li>
            {" "}
            <img src={check} className="img-fluid check-push" alt="check" />
            Start Presales on Popular Launchpads
          </li>
          <li>
            {" "}
            <img src={check} className="img-fluid check-push" alt="check" />
            Training and retraining of staff
          </li>
        </ul>
      ),
    },
    {
      title: "April to June 2024",
      cardTitle: "Listing Eda Token",
      url: "",
      cardSubtitle: ``,
      cardDetailedText: (
        <ul className="time-line">
          <li>
            {" "}
            <img src={check} className="img-fluid check-push" alt="check" />
            Redesigning EdaFace website to be multi-linguistic and more
            capabilities
          </li>
          <li>
            {" "}
            <img src={check} className="img-fluid check-push" alt="check" />
            Complete the Presales
          </li>
          <li>
            {" "}
            <img src={check} className="img-fluid check-push" alt="check" />
            Listing of Eda Token on Exchanges
          </li>
        </ul>
      ),
    },
    {
      title: "July to September 2024",
      cardTitle: "EdaFace Tutor & VAC",
      url: "",
      cardSubtitle: ``,
      cardDetailedText: (
        <ul className="time-line">
          <li>
            {" "}
            <img src={check} className="img-fluid check-push" alt="check" />
            Unveiling of EdaFace Tutor – the AI bot
          </li>
          <li>
            {" "}
            <img src={check} className="img-fluid check-push" alt="check" />
            Documentation and Designing of Verification Asset Centre (VAC)
          </li>
          <li>
            {" "}
            <img src={check} className="img-fluid check-push" alt="check" />
            Intensive Marketing efforts
          </li>
        </ul>
      ),
    },
    {
      title: "October to December 2024",
      cardTitle: "Phase Three Development",
      url: "",
      cardSubtitle: ``,
      cardDetailedText: (
        <ul className="time-line">
          <li>
            {" "}
            <img src={check} className="img-fluid check-push" alt="check" />
            Preparation for Phase Three (Blockchain) Construction
          </li>
          <li>
            {" "}
            <img src={check} className="img-fluid check-push" alt="check" />
            Intensive Marketing efforts
          </li>
          <li>
            {" "}
            <img src={check} className="img-fluid check-push" alt="check" />
            Further audit of smart contracts
          </li>
        </ul>
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Nav></Nav>
      <div className="token-bac pt-5">
        <div className="container">
          <div className="row mt-5">
            <h1
              className="token-header"
              style={{ textAlign: "center", fontSize: "30px" }}
            >
              EdaFace Roadmap
            </h1>
            <p className="about-body-m">
              The followings are details of the Road Map for the various
              utilities as they are constructed through their various stages of
              development, which include conceptualisation, designing of
              whitepapers, formation of Development Team, release of alpha and
              beta construction stages, mainnet, and finally launching.
            </p>
            <div
              className="roadmap-legend center"
              style={{ textAlign: "center" }}
            >
              {" "}
              <span className="center" style={{ marginRight: "20px" }}>
                <img
                  src={checkOutline}
                  className="img-fluid check-push"
                  alt="check"
                />
                Completed
              </span>
              <span className="center">
                <img src={check} className="img-fluid check-push" alt="check" />
                Pending
              </span>
            </div>
            <div className="timeline">
              <Chrono
                items={data}
                useReadMore={false}
                // Theming for timeline
                theme={{
                  primary: "#0f52ba",
                  secondary: "#0dcaf0",
                  cardBgColor: "#fff",
                  cardForeColor: "#fff",
                  titleColor: "#0f52ba",
                  titleColorActive: "#fff",
                }}
                mode="VERTICAL"
                buttonTexts={{
                  first: "Jump to First",
                  last: "Jump to Last",
                  next: "Next",
                  previous: "Previous",
                }}
                slideShow
                enableOutline
              />
            </div>
          </div>
        </div>
      </div>

      <Footer2></Footer2>
    </div>
  );
};

export default Roadmap;
