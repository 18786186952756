import React from "react";
import { useEffect } from "react";
import Nav from "../partials/nav";
import Footer2 from "../partials/footer2";

const Stakability = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Nav></Nav>

      <div className="token-bac">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card-token mb-5">
                <h3 className="token-header">Token Distribution Analysis</h3>
                <p>
                The following is the summary explanation of the distribution of Eda Token:
                </p>
                <p>
                  <span className="token-holder">
                  1.0. Maximum Supply
                  </span>
                </p>

                <p>
                EDA is a non-mintable coin. This means the maximum supply of the token that will ever be in existence was the already minted 100 billion. 
                </p>
                <p>
                The idea behind this seemingly large amount of maximum supply is that the token will be utilized in decentralized finance (DeFi) payment activities.
                </p>
                <p>In addition, Eda Token is designed to be a currency for daily transactional activities. This means that it should still be affordable to every person and institution even when its market capitalization has grown.</p>
                <p>A moderately large maximum supply will mean an affordable and accessible price even at a large market capitalization.</p>
                <p>Thus, $EDA avoids the mistake of many other coins such as Bitcoin, which has a maximum supply of 21 million and thereby becomes too expensive at a large market capitalization, and has failed in its mission of becoming a true currency.</p>

                <p>
                  <span className="token-holder">
                  2.0. Burning of EDA
                  </span>
                </p>
                  <p>On deployment onto the BNB Smart Chain, 20% of EDA were permanently and irretrievably burnt.</p>
                  <p>Since the Market Price of a coin is inversely proportional to its Circulating Supply (see Equation 1 below), the reason for the burnt off of $EDA on deployment to the BNB Smart Chain is to decrease its total and overall circulating supply so that holders of the token can see an appreciable increase in price.</p>

                           <p className="text-center"><span style={{marginRight: "2em"}}><i>Price = Market Capitalization / Circulating Supply</i></span>  <span>[Equation 1]</span></p>

                  <p>This means the Total Supply of $EDA on deployment was 80 billion, which was neither too small nor too large but just moderate enough to become a true currency even at a large market capitalization.</p>

                  <p>Also, another 10% of Maximum Supply of EDA has been allocated for a 5-year progressive burning to stimulate price growth.</p>
                
                  <p>
                  <span className="token-holder">
                  3.0. Presales
                  </span>
                </p>
                  <p>EdaFace has secured partnerships with a number of popular Launchpads to run Presales for Eda Token.</p>
                  <p>The reason for this is to increase the awareness of EdaFace with its Eda Token and raise funds for further development of EdaFace and listing of Eda Token in exchanges.</p>
                  <p>As such, 20% of Eda Token has been set aside for the Presales. 50% of the total funds raised from Presales will be used to supply liquidity for the token.</p>


                  <p>
                  <span className="token-holder">
                  4.0. Listing on Exchanges
                  </span>
                </p>
                  <p>After the Presales, Eda Token will be listed in centralized and decentralized exchanges. 10% of Eda Token have allocated for the listing on Centralized and Decentralized Exchanges.</p>


                <p>
                  <span className="token-holder">
                  5.0. Venture Capital Allocation
                  </span>
                </p>

                  <p>At the conceptualization of the EdaFace project, some individuals (many of whom were not part of the Team) came together and sacrificed their time, energy and resources for the take-off.</p>

                  <p>Five percent of Eda Token have been allotted as compensation for these gallant efforts.</p>

                <p>
                  <span className="token-holder">
                  6.0. DeFi Allocation
                  </span>
                </p>
                <p>
                EdaFace Decentralized Exchange (EdaFace Dex) is where the DeFi activities will be taking place. The platform is currently at the alpha stage of development.
                </p>
                <p>
                EdaFace Dex offers decentralized financial inclusive activities such as Pool Staking, Liquidity Mining with Yield Farming, Lending and Borrowing, etc.
                </p>
                <p>
                The reward system for these DeFi activities is performed with Eda Token since the token is the native utility for the EdaFace Ecosystem. 
                </p>
                <p>
                As such, a good amount of the token (11% of the maximum supply) is required for these varied DeFi activities.
                </p>

                <p>
                  <span className="token-holder">
                  7.0. Blockchain Development Allotment
                  </span>
                </p>
                  <p>Part of the vision of the EdaFace ecosystem is to build a layer-one blockchain for Eda Token, which is currently on BNB Smart Chain.</p>
                  <p>Just like BNB, which started as a token on the Ethereum chain and later migrated to its own blockchain, Eda Token will migrate to its own blockchain in the course of the construction of the chain.</p>
                  <p>To develop a layer-one public blockchain and incentivize the public, 10% of $EDA has been assigned for this purpose. This means holding $EDA for a long time will ultimately result in good profits for the holder.</p>


                <p>
                  <span className="token-holder">
                  8.0. Community Rewards
                  </span>
                </p>
                  <p>As a way to incentivize the rapidly growing EdaFace communities and trigger larger market penetration, 3% of Eda Token have been allocated for these purposes.</p>
                  <p>Moreso, EdaFace is partnering with key and strategic partners to promote its vision, enlarge its communities, and also driving the wider adoption of blockchain technology.</p>
                 
                <p>
                  <span className="token-holder">
                  9.0. Charities 
                  </span>
                </p>
                  <p>In line with its vision, EdaFace is designed to provide welfare-supportive systems in various aspects of life such as Gospel-related activities, academic scholarship awards, research promotions in healthcare delivery, and sports promotion.</p>
                  <p>As such, 1% of Eda Token has been set aside as the initial trigger for these selfless, lofty ideals.</p>

                
              </div>
            </div>

            <div className="col-md-12">
              <div className="card-token mt-0">
                <h3 className="token-header">Locked Team Allocation</h3>
                <p>EdaFace has been founded by the voluntary association of members. To incentivize these Team members to keep rendering their sacrifices, 10% of Eda Token have been allotted to the entire Team.</p>
                <p>However, Team members understandably agreed that their Eda Token allotment would be locked from trading circulation for 18 months to allow the token and its utilities to grow and mature.</p>

               
                <p className="token-holder">
                This measure is also a way of building trust and confidence in the Team within the rapidly growing EdaFace communities.</p>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2></Footer2>
    </div>
  );
};

export default Stakability;






