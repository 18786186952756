import React from "react";
import Nav from "../partials/nav";
import Footer2 from "../partials/footer2";
import { useEffect } from "react";

const Character = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Nav></Nav>

      <div className="token-bac">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="cha-header">Eda Token Utilities</h3>
              <p className="cha-sub-header">
                Eda Token has multi-functional use cases. These multi-functional
                utilities collectively form the EdaFace Ecosystem. Some of the
                identified use cases for EDA Token are discussed below. Note
                that as EdaFace Ecosystem develops and evolves, more utilities
                for the token will appear.
              </p>
              <p className="cha-sub-header"></p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="card-token-2">
                <h3 className="token-header"> EdaFace Blockchain Literature</h3>
                <p>
                    This unique component allows anyone to create and list their
                    creative products on EdaFace using blockchain technology.
                    Such blockchain literatures include books, certificates,
                    songs, videos, etc.
                </p>
                <p>
                  Here are the heighlights of what can be done on EdaFace
                  Blockchain Literature:
                </p>

                <p>(a). Digital Content Publishing and Selling</p>

                <p>(b). Identity Verification of Creative Works</p>

                <p>
                  (c). Certiﬁcate Veriﬁcation of academic and non-academic
                  certiﬁcates
                </p>

                <p>
                  (d). Creators can mint their products in Varieties of ways
                  such as PDF and NFT formats
                </p>

                <p>(e). Free Time as Promotional Bait</p>

                <p>(f). Document Notarisation</p>

                <p>(g). Proof of Origin of products</p>

                <p>(h). Product Quality Veriﬁcation</p>

                <p className="token-utility">
                Note that these functionalities already exist on EdaFace
                    Blockchain Literature and can be accessed at... https://nft.edaface.com/.
                </p>

                <p>
                  The good news for Eda Token holders is that for all these
                  activities, the creators and the users of EdaFace Blockchain
                  Literature interact on the platform via Eda Token.
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card-token-2">
                <h3 className="token-header">EdaFace Dex</h3>
                <p>
                  EdaFace Dex is a decentralized exchange that uses a modified
                  Automated Market Maker (mAMM) module. It offers a number of
                  utilities that are briefly outlined here:
                </p>

                <p>(a). Faster and Seamless Spot transactions</p>
                <p>
                  (b). Friendly User Interface akin to to the features of a
                  centralized exchange
                </p>
                <p>
                  (c). Fiat currency transactions using the principle of digital
                  asset tethering
                </p>
                <p>
                  (d). Introduces MIM Pool model that offers unlimited staking
                  ability of digital assets
                </p>
                <p>
                  (e). Offers Derivatives Trading in fixtures, synthetics, and
                  options
                </p>
                <p>
                  (f). Liquidity mining as well as yield farming also take place
                  on EdaFace Dex
                </p>
                <p>(g). Offers decentralized Lending and Borrowing services.</p>

                <p className="token-utility">
                To utilize these services, users of the platform will
                interact with Eda Token as the means of payment for services.
                </p>

                <p>
                  Meanwhile, please note that EdaFace is in the alpha stage and
                  it is, as such, not yet released to the public. However, you
                  can follow its development stages on EdaFace Forum platform:
                  https://forum.edaface.com/.
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card-token-2">
                <h3 className="token-header">NFT Marketplace</h3>
                <p>
                  This component offers the following utilities to Eda Token:
                </p>

                <p>
                  (a). Five Varieties of Minting Standards (Single, Unimultiple,
                  Polymultiple, Layer-by-Layer, and Lazy mintings) - the first
                  of its kind in the industry!
                </p>

                <p>(b). Tokenization of assets</p>

                <p>(c). Collectible Hunting</p>

                <p>(d). Gaming activities</p>

                <p>(e). Multichain platform</p>

                <p>
                  (f). Intrachain transfer of NFTs from other platforms to
                  EdaFace and vice versa.
                </p>

                <p className="token-utility">
                  The interesting thing for token holders is that creators and
                  the users of EdaFace NFT Marketplace interact on the platform
                  via Eda Token.
                </p>
                <p>
                  You can access EdaFace NFT Marketplace at...
                  https://nft.edaface.com/.
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card-token-2">
                <h3 className="token-header">EdaFace Listing Platforms</h3>
                <p>
                  This is a component of EdaFace that is crucial for the entire
                  blockchain industry. This is because it tracks and displays all
                  the legitimate coins, tokens, marketplaces, etc. in the market.
                </p>
                <p>The following is a summary of the utilities offer by this platform:</p>

                <p className="token-utility">
                  (a). Market Reflectors
                </p>
                <p>
                  EdaFace Listing Platforms are markets reflectors as they display
                  various aspects of the Crypto Market in real time. Thus, users
                  will see the following items being displayed:
                </p>
                <p>(i). All the legitimate cryptocurrencies in the market</p>
                <p>
                  (ii). All the exchanges offering spot trading in the market
                </p>
                <p>
                  (iii). All the exchanges offering various derivatives contracts
                  such as synthetics, futures, and options in the market
                </p>
                <p>(iv). All the decentralized exchanges in the market</p>
                <p>
                  (v). All the marketplaces that are available in the blockchain
                  industry
                </p>

                <p className="token-utility">
                (b). Market Trends
                </p>
                <p>
                  EdaFace Listing Platforms tracks and displays crypto market
                  trends using friendly trading graphs and customizable trading
                  instruments.
                </p>
                <p>
                  Thus, there are daily Top Gainers and Top Losers and traders can
                  subscribe for notification via their phones and emails that can
                  boost their trading profits.
                </p>

                <p className="token-utility">
                    For all these utilities on EdaFace Listing Platforms, Eda
                    Token is the means by which traders interact with the
                    platforms.
                </p>
                <p>
                  EdaFace Listing Platforms is in its beta stage and can be
                  accessed at: https://listing.edaface.com/.
                </p>
              </div>
            </div>
            
            <div className="col-md-6">
              <div className="card-token-2">
                <h3 className="token-header">EdaFace P2P Market</h3>
                <p>
                  This platform is designed to bring buyers
                  and sellers of digital assets together for a
                  simplified, seamless, secure, and enjoyable trading experience
                  with no centralised mediation.
                </p>
                <p>
                  Here are the heighlights of what can be done on EdaFace P2P
                  Market:
                </p>

                <p>(a). Currency Exchange and Remittance</p>

                <p>(b). Secure P2P Payments</p>

                <p>(c). Direct interaction between buyers and sellers</p>

                <p className="token-utility">To utilize these services, users of the platform will interact with Eda Token as the means of payment for services.</p>
                
                <p>EdaFace P2P Market is in its beta stage of development and can be accessed at… https://p2pmarket.edaface.com/.</p>
              
              </div>
            </div>

            <div className="col-md-6">
              <div className="card-token-2">
                <h3 className="token-header">EdaFace Launchpad</h3>
                <p>
                  This decentralised platform offers the following utilities:
                </p>

                <p>
                  (a). Advertisement of Presales such as Initial coin Offer
                  (ICO)
                </p>
                <p>
                  (b). Initial Dex Offer (IDO) in conjunction with EdaFace Dex
                </p>
                <p>(c). Crowd funding of projects</p>
                <p>(d). Digital Equity Sales</p>

                <p className="token-utility">
                Users interact on the platform using Eda Token as the means of payment for services. 
                </p>
                <p>
                  You can access EdaFace Launchpad at:
                  https://launchpad.edaface.com/.
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card-token-2">
                <h3 className="token-header">EdaFace Mall</h3>
                <p className="intro-token">
                  EdaFace Mall is an online shopping complex that links physical
                  products to online services. EdaFace Mall is the place for
                  e-commerce. It is the link between the digital world and the
                  physical world.
                </p>
                <p>
                  However, unlike in traditional mall, EdaFace Mall is
                  completely decentralized and offers the following utilities:
                </p>

                <p>(a). Merchandising</p>
                <p>(b). Fiat and Crypto Payment services</p>

                <p className="token-utility">
                    As with other EdaFace platforms, to utilize these services,
                    users of the platform will interact with Eda Token as the
                    means of payment for services.
                </p>
                <p>
                  EdaFace Mall is in its beta stage of development and can
                  accessed here… https://mall.edaface.com/.
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card-token-2">
                <h3 className="token-header">Digital Clinic</h3>
                <p>
                  This unique component of EdaFace seeks to digitize healthcare
                  system in Africa and beyond. It will be offering the following
                  utilities:
                </p>
                <p>(a). Cloud Storage of healthcare data</p>
                <p>(b). Identity Data Management</p>
                <p>
                  (c). Healthcare providers and consumers can sign contracts
                  digitally and store the encrypted contracts in a secure way
                </p>
                <p>
                  (d). IoT Sensor Data Purchasing in which generated healthcare
                  data are only accessed by those authorised to do so.
                </p>

                <p className="token-utility">
                    To utilize these services, users of the platform will
                    interact with Eda Token as the means of payment for
                    services.{" "}
                </p>
                <p>
                  Meanwhile, EdaFace Digital Clinic is in its alpha stage of
                  development, hence, not yet accessible to the public. However,
                  you can follow its development at https://clinic.edaface.com/.{" "}
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card-token-2">
                <h3 className="token-header">School of Cryptocurrencies</h3>
                <p>
                  The School of Cryptocurrencies (SOC) of EdaFace Academy is the
                  interactive learning platform of EdaFace. It offers the
                  following utilities:
                </p>

                <p className="token-utility">
                  (a). Didactic Teaching
                </p>
                <p>
                  The SOC is an enrolment academy and the teachings are in three
                  levels – Primary, Secondary, and Tertiary.{" "}
                </p>
                <p>
                  The learning progresses from the basics of the Primary SOC in
                  which the student will learn how to maximize his crypto
                  investment, to the intermediate level of the Secondary SOC in
                  which he will be empower to start generating continuous
                  passive incomes in the Crypto Market.
                </p>
                <p>
                  The didactic teaching ends in the Tertiary SOC where the
                  student is exposed to how to accelerate wealth from crypto
                  investment.
                </p>

                <p className="token-utility">
                  (b). Practicals
                </p>
                <p>
                  The SOC is equipped with graded practical exercises that help
                  the student to practice what he learns.
                </p>
                <p>
                  The teaching interaction is decentralised in which the student
                  interacts with virtual teachers, EdaFace Tutors, via various
                  audiovisuals as well electronic books, NFT books, and
                  hardcopies.
                </p>
                <p>EdaFace Tutors are inbuilt artificial intelligence.</p>

                <p className="token-utility">
                  (c). Certification
                </p>
                <p>
                  At the end of each teaching level, there is automated graded
                  questions that test the student knowledge of what he has
                  studied.
                </p>
                <p>
                  The school automatedly prints out a certificate that is based
                  on the student scores with the help of EdaFace Tutor.
                </p>

                <p className="token-utility">
                    Note all interactions in the school are done via Eda Token.
                    In other words, EdaFace SOC provides great utilities for Eda
                    Token.
                </p>
                <p>
                  EdaFace SOC is in its beta stage and can be accessed at:
                  https://school.edaface.com/.
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card-token-2">
                <h3 className="token-header">Verification Asset Centre</h3>
                <p>
                  The Verification Asset Centre (VAC) of EdaFace is designed to
                  identify cryptocurrency scams, especially rug pulls. The VAC
                  offers the following utilities:
                </p>

                <p className="token-utility">
                  (a). Verification of Assets
                </p>
                <p>
                  The VAC uses EdaFace SOC Scorecard designed by EdaFace Academy
                  to detect a scam coin.
                </p>
                <p>
                  The SOC Scorecard is made up of 15 parameters that have been
                  grouped into 5 titles. It has 96% accuracy rate in detecting
                  scam coins.
                </p>

                <p className="token-utility">
                  (b). Asset Grading
                </p>
                <p>
                  Besides verifying the genuineness of an asset, the VAC centre
                  also grade the asset as follows:
                </p>
                <li>0 – 20% scores: Bad Investment</li>
                <li>21 – 40% scores: Cautious Investment</li>
                <li>41 – 60% scores: Good Investment</li>
                <li>61 – 80% scores: Very Good Investment</li>
                <li>81 – 100% scores: Awesome Investment</li>

                <p>
                  This Scorecard grading will guide a user in rating the
                  profitability of any digital asset and how to spread his
                  investment risk in digital assets.
                </p>

                <p className="token-utility">
                  (c). Investment Forecast
                </p>
                <p>
                  Another good thing about EdaFace SOC Scorecard is that it
                  gives a four year forecast of digital assets.
                </p>
                <p>
                  In other words, users can utilize the VAC platform to forecast
                  the outcome of their investment within the error margin of
                  acceptable predictability.
                </p>

                <p className="token-utility">
                   The good news is that users interact with these activities
                    using Eda Token. Indeed, the VAC provides formidable
                    utilities for Eda Token.
                </p>
                <p>
                  The VAC is in its alpha stage of development, and once it is
                  ready, it will be announced on all EdaFace community
                  platforms.
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card-token-2">
                <h3 className="token-header">EdaFace Newsfeed</h3>
                <p>
                  EdaFace Newsfeed has been built and launched to update users
                  regularly and in real time of all the news and development in
                  the blockchain industry and the digital world in general as
                  the news unfold in the digital space.
                </p>
                <p>EdaFace Newsfeed offers the following utilities:</p>

                <p className="token-utility">
                  (a). Real-time News in the Industry
                </p>
                <p>
                  EdaFace Newsfeed covers news from the blockchain industry,
                  artificial intelligence, and the digital space in general.
                </p>

                <p className="token-utility">
                  (b). Advertisement
                </p>
                <p>
                  EdaFace Newsfeed platform also provides opportunity for
                  individuals, companies, and institutions to showcase their
                  activities and products to the teeming EdaFace communities.
                </p>

                <p className="token-utility">
                  For all these interaction, the user needs Eda Token.
                </p>

                <p>
                  EdaFace Newsfeed is in functional and can be accessed at:
                  https://news.edaface.com/.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2></Footer2>
    </div>
  );
};

export default Character;





