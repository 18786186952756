import React from "react";
import about from "../../images/about.png"; 
import chevron from "../../images/chevron.svg";
import { Link } from "react-router-dom";

const About =()=> {

    return(
        <div>

<div className="about-bac">
    <div className="container">
        <div className="row">
            <div className="col-lg-6">
<img src={about} className="img-fluid about" alt="about"/>
            </div>
            <div className="col-lg-6">
                {/*
            <h6 className="about-intro">WHAT IS A CRYPTO AGGREGATOR?</h6>
    */}
<h3 className="about-header">About EdaFace</h3>
<p className="about-body">Discover EdaFace: Your Gateway to the Crypto World and Beyond!</p>  
<p className="about-body">EdaFace offers a comprehensive platform where you can track your favorite cryptos, engage in DeFi, trade and mint NFTs, explore the metaverse world, transact in digital products like music and books, and stay updated with crypto news.</p>         
<p className="about-body">Experience the EdaFace Launchpad for advertising, launching, and crowdfunding your crypto projects.</p>
<p className="about-body">With our Crypto Verification Centre, EdaFace identifies and helps you avoid scams, including rug pulls, making it the first of its kind in the crypto industry. Join us and navigate the crypto world with confidence!</p>
<Link to="/tokenomics"><button className="btn-edaface">Whitepaper <span><img src={chevron} className="img-fluid" alt="button-icon"/></span></button></Link>
<a href = "https://launchpad.edaface.com/white-paper"  target="_blank" rel="noreferrer noopener"><button className="btn-edaface">Litepaper <span><img src={chevron} className="img-fluid" alt="button-icon"/></span></button></a>
            </div>
        </div>
    </div>
</div>
        </div>
    )


}

export default About ; 

