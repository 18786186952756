import React from "react";
import { useEffect } from "react";
import Nav from "../partials/nav";
import Footer2 from "../partials/footer2";

import Chart from "../partials/chart";

const Tokenomics = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Nav></Nav>
      <div className="token-bac">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card-token mb-0">
                <h3 className="token-header">Tokenomics</h3>
                <p className="intro-token">
                  EDA is the native utility token for EdaFace and the EDA
                  ecosystem in general. The token is an ethical,
                  community-driven cryptocurrency that rewards long-term holding
                  with high-yield interest rates.
                </p>
                <p className="token-caster">
                  <span className="token-holder">Name:</span>Eda Coin
                </p>
                <p className="token-caster">
                  <span className="token-holder">Abbreviation:</span>EDA
                </p>
                <p className="token-caster">
                  <span className="token-holder">Blockchain:</span>BNB Smart
                  Chain
                </p>
                <p className="token-caster">
                  <span className="token-holder">Format:</span>BEP20 Token
                </p>
                <p className="token-caster">
                  <span className="token-holder">Decimals:</span>18
                </p>
                <p className="token-caster">
                  <span className="token-holder">Maximum supply:</span>100
                  billion
                </p>
              </div>
            </div>

            <div className="col-md-12">
              <div className="card-token mt-5">
                {/* Ddb react chart */}
                {/* <img src={piechart} className="img-fluid" alt="piechart" /> */}
                <h3 className="token-header">Token Distribution (%) </h3>
                <Chart></Chart>

                <p className="token-caster">
                  <span className="token-holder">Burning Allocation: </span> 20%
                </p>
                <p className="token-caster">
                  <span className="token-holder">Presale: </span> 20%
                </p>
                <p className="token-caster">
                  <span className="token-holder">
                    LP Farming and Ecosystem Growth:{" "}
                  </span>{" "}
                  11%
                </p>
                <p className="token-caster">
                  <span className="token-holder">Team Allocation: </span> 10%
                </p>

                <p className="token-caster">
                  <span className="token-holder">Blockchain Development: </span> 10%
                </p>

                <p className="token-caster">
                  <span className="token-holder">5-Year Progressive Burn: </span> 10%
                </p>
                
                <p className="token-caster">
                  <span className="token-holder">VC Round: </span>
                  5%
                </p>
                <p className="token-caster">
                  <span className="token-holder">Cex Listing: </span> 5%
                </p>
                <p className="token-caster">
                  <span className="token-holder">Dex Listing: </span> 5%
                </p>
                <p className="token-caster">
                  <span className="token-holder">Community Rewards: </span> 3%
                </p>
                <p className="token-caster">
                  <span className="token-holder">Welfare: </span>1%
                </p>
                <p className="token-caster">
                  <span className="token-holder">Company Reserve: </span>0%
                </p>
                <p className="token-caster">
                  <span className="token-holder">Strategic Allocation: </span>0%
                </p>
                <p className="token-caster">
                  <span className="token-holder">Unlocked: </span>0%
                </p>
              </div>
              <div className="card-token mt-0">
                <h3 className="token-header">Audit/Security of Token</h3>
                <p className="intro-token">
                  Eda Coin’s contract has been audited by Cyberscope. You can access the audit report here: https://github.com/cyberscope-io/audits/blob/main/eda/audit.pdf 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2></Footer2>
    </div>
  );
};

export default Tokenomics;
