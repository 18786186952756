import React from "react";
import { useEffect } from "react";
import Nav from "../partials/nav";

import Footer2 from "../partials/footer2";

const Ico = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Nav></Nav>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="card-token">
              <h3 className="token-holder">Fund Raising Target</h3>
              <p className="intro-token">
                Softcap = 35 BNB = 10 500 USD (at 1BNB = 300 USD)
              </p>
              <p className="intro-token">
                Hardcap = 126 142 BNB = 37 842 600 USD (at 1BNB = 300 USD)
              </p>
              <br />

              <h3 className="token-holder">Presale Phases</h3>
              <p className="intro-token">
              The Presales will run in Phases on EdaFace Launchpad. 
              </p>
              <p className="intro-token">
                In addition, EdaFace has entered into partnership with a number of popular Launchpads in the market for the launching of Eda Token Presales. The aim is to make the token available to a large number of persons and create awareness of the unique features of the token in the industry. 
              </p>

              <p className="intro-token">
              As Eda Token Presales unfold, the various partnership Launchpads will be announced. Therefore, watch out for the announcements across EdaFace social media platforms.
              </p>

              <p className="intro-token">
                Starting Presale Price is 0.000 000 03 USD, and this will progressively increase as the Presale Phases advance in order to meet the hardcap.
              </p>
              
              <p className="intro-token">
                <span className="token-holder">Unsold Tokens</span>
              </p>
                <li>• 20% of unsold Presale tokens will be burnt</li>
                <li>• 20% of unsold Presale tokens will go for marketing.</li>
                <li>• 60% of unsold Presale tokens will be added to the DeFi allocation</li>
   
            </div>
          </div>

          <div className="col-md-12">
            <div className="card-token mt-0">
              <div className="data-table mt-0">
                <div className="card shadow">
                  <div className="card-header py-2">
                    <p
                      className="lead text-info m-0"
                      style={{
                        color: "#26b6d4",
                        fontWeight: "semi-bold",

                        fontFamily: "Poppins, sans-serif",
                        textAlign: "center",
                      }}
                    >
                      <strong>
                      Target Listing Price
                      </strong>

                    </p>
                  </div>
                  <div className="card-body">
                  
                  <p className="intro-token">
                    At the conclusion of the Presales, Eda Token will be listed in decentralized and centralized exchanges.
                  </p>

                  <p className="intro-token">
                    The target listing price for one $EDA is <b>0.000 001 USD</b>. This will give a profit of <b>more than 3 200%</b> for those who join the Presales early.
                  </p>

                  <p className="intro-token">
                    However, the listing price of Eda Token will depend on overall amount of funds raised during the Presales. The good news is that with more fund raising, a higher listing price can be achieved.
                  </p>

                    <div
                      className="table-responsive table mb-0 pt-3 pe-2"
                      style={{ fontFamily: "Poppins, sans-serif" }}
                    >

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2></Footer2>
    </div>
  );
};

export default Ico;
