import Footer2 from "../partials/footer2";
import Nav from "../partials/nav";
import { useEffect } from "react";

//Import images
import pinksale from "../../images/pinksale.png";
import dexview from "../../images/Dexview.png";
import linkedin from "../../images/browser.svg";
import bnbsmartchain from "../../images/Binance.png";
import dcentralab from "../../images/DcentraLab.png";
import Gempad from "../../images/Gempad.png";
import Pancakeswap from "../../images/pancakeswap.png";

const Partners = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Nav></Nav>
      <div className="token-bac">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="cha-header">Partners</h3>
              <p className="cha-sub-header" style={{ textAlign: "left" }}>
                EdaFace has entered into partnership with strategic leading
                companies in the industry. Below are some of our Partners.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>

            <div className="col-md-2"></div>
          </div>
        </div>
        <div className="wrapper-profile">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img
                  src={pinksale}
                  className="img-fluid partners"
                  alt="profile"
                />
                <h4 className="found-name">PinSale</h4>
                <h6 className="found-pos">Crypto launchpad</h6>

                <a
                  href="https://www.pinksale.finance/launchpad/0x834741481845AA5ABe5CAc962639b9493A5570A8?chain=BSC/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src={linkedin}
                    className="img-fluid linkedin"
                    alt="linkedin"
                  />
                </a>
              </div>

              <div className="col-md-6">
                <img
                  src={dexview}
                  className="img-fluid partners rounded"
                  alt="profile"
                />
                <h4 className="found-name">DEXView</h4>
                <h6 className="found-pos">
                  A Platform for Realtime Price Charts
                </h6>

                <a
                  href="https://www.dexview.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src={linkedin}
                    className="img-fluid linkedin"
                    alt="linkedin"
                  />
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <img
                  src={bnbsmartchain}
                  className="img-fluid partners"
                  alt="profile"
                />
                <h4 className="found-name">BNB Smart Chain</h4>
                <h6 className="found-pos">Eda Token on BNB Smart Chain</h6>

                <a
                  href="https://bscscan.com/token/0x6fd2233fc16474688517f2a79472465fae00a9e8"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src={linkedin}
                    className="img-fluid linkedin"
                    alt="linkedin"
                  />
                </a>
              </div>

              <div className="col-md-6">
                <img
                  src={dcentralab}
                  className="img-fluid partners"
                  alt="profile"
                />
                <h4 className="found-name">DcentraLab</h4>
                <h6 className="found-pos">The Ultimate Cross-Chain Bridge</h6>

                <a
                  href="https://www.dcentralab.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src={linkedin}
                    className="img-fluid linkedin"
                    alt="linkedin"
                  />
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <img
                  src={Gempad}
                  className="img-fluid partners"
                  alt="profile"
                />
                <h4 className="found-name">GemPad</h4>
                <h6 className="found-pos">A Multichain DeFi Launchpad</h6>

                <a
                  href="https://gempad.app/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src={linkedin}
                    className="img-fluid linkedin"
                    alt="linkedin"
                  />
                </a>
              </div>

              <div className="col-md-6">
                <img
                  src={Pancakeswap}
                  className="img-fluid partners"
                  alt="profile"
                />
                <h4 className="found-name">Pancakeswap</h4>
                <h6 className="found-pos">
                  Everyone’s favorite Decentralized Exchange
                </h6>

                <a
                  href="https://pancakeswap.finance/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src={linkedin}
                    className="img-fluid linkedin"
                    alt="linkedin"
                  />
                </a>
              </div>

              <div className="space"></div>
            </div>
          </div>
        </div>
      </div>

      <Footer2></Footer2>
    </div>
  );
};

export default Partners;
